import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import './ScheduleItem.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import {Application} from "../types/Application";

export default function ScheduleItem({schedule, applications, header}: { schedule: any, applications: Application[], header:any}) {
  dayjs.extend(timezone);
  dayjs.extend(utc);

  const start = dayjs(schedule?.start).tz("Asia/Tokyo")
  const end = dayjs(schedule?.end)

  const getApp = (id: number): Application | null => {
    return applications.find(app => app.id === id) ?? null;
  }

  return (
    <div className="schedule-item">
      <div className="date">
        <span className="year">{start.year()}</span>
        <div className="cal">
          <span className="month">{start.format("M")}月</span>
          <span className="day">{start.format("D")}</span>
        </div>
      </div>
      <div className="time">
        {getApp(schedule.application_id)?.name}<br />
        <span className="tag is-info">
          開始: {`${start.format("HH:mm")}`}／終了: {`${end.format("HH:mm")}`}
        </span>
      </div>
      <div className="trash">
        <button className="button is-danger" onClick={async (e) => {
          e.preventDefault()
          const target = e.currentTarget.closest('div.schedule-item');
          await axios.post(`https://api.miscolle.com/v1/schedule/delete/${schedule.id}`, {},
            {
              headers: header
            })
          const animation = target?.animate({
            opacity: [1, 0],
          }, {duration: 300, easing: 'ease-in', fill: "forwards"});
          if (animation) {
            animation.onfinish = (ev) => {
              target?.classList.add("is-hidden");
            }
          }
        }}>
          <FontAwesomeIcon icon={faTrash}/>
        </button>
      </div>
    </div>
  );
}
