import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import './style.scss';
import axios from 'axios';
import {Application} from "./types/Application";
import {Finalist} from "./types/Finalist";
import {InputValues} from "./types/InputValues";
import Logo from './miscolle.svg';
import ScheduleItem from "./components/ScheduleItem";
import dayjs from "dayjs";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle, faCalendar} from '@fortawesome/free-solid-svg-icons';
import * as url from "url";

import Loader from 'react-loader-spinner';

function App() {
  const [schedules, setSchedules] = useState<any[]>([]);
  const [finalist, setFinalist] = useState<Finalist | null>(null);
  const [applications, setApplications] = useState<Application[]>([]);
  const [inputValue, setInputValue] = useState<InputValues>({});
  const [header, setHeader] = useState<any | null>(null);
  const [endTime, setEndTime] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {query} = url.parse(window.location.href, true);

  useEffect(() => {
    setHeader({
      "x-access-token": "f5d271dbb25d87b460a715d4ed5dda86f62df25cd352f531da3d8bd5d373803d",
      "x-finalist-token": query.uid ?? ""
    })
  }, [query.uid])

  useEffect(() => {
    if (header === null)
      return
    const pArray = [
      axios.get("https://api.miscolle.com/v1/schedule/apps", {
        headers: header
      }),
      axios.get("https://api.miscolle.com/v1/schedule/", {
        headers: header
      })
    ];

    Promise.all(pArray).then((res) => {
      const {applications} = res[0].data
      const {finalist, schedules} = res[1].data;

      setApplications(applications);
      setFinalist(finalist)
      setSchedules(schedules)
      setIsLoading(false);

    }).catch(() => {
      // alert('情報の取得に失敗しました')
      console.error("情報の取得に失敗しました");
    });

  }, [header])


  return (
    <>
      {
        isLoading &&
        (
          <div style={{position: "absolute", zIndex: 100, width: "100%", height: "100vh", backgroundColor: "#ffffff"}}>
            <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
              <Loader
                type={"Watch"}
                color={"purple"}
              />
            </div>
          </div>
        )
      }
      <div id="App">
        <Helmet>
          <meta charSet="utf-8"/>
          <title>ライブ配信 ≫ スケジュール登録</title>
        </Helmet>
        <header>
          <img src={Logo} alt="MISCOLLE" className="logo"/>
          <h2
            className="has-text-weight-bold is-size-5 has-text-white-bis">{`${finalist?.last_name} ${finalist?.first_name} さん`}</h2>
          <h1 className="is-size-4 has-text-weight-bold has-text-white-bis">スケジュール登録</h1>
        </header>
        <div className="container">
          <div className="body">
            <h3 className="is-size-6 has-text-weight-bold">スケジュールの追加</h3>
            <div className="form">


              <div className="select is-small">
                <select onChange={(e) => {
                  setInputValue({...inputValue, application_id: parseInt(e.target.value)})
                }}
                        value={inputValue.application_id}>
                  <option>プラットフォーム</option>
                  {
                    applications.map(app => {
                      return (
                        <option key={app.id} value={app.id}>
                          {app.name}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="timepicker">
                  <p className="control has-icons-left has-icons-right">
                    <input className="input is-small" type="date" onChange={(e) => {
                      setInputValue({...inputValue, date: e.target.value, endDate: e.target.value})
                    }}/>
                    <span className="icon is-small is-left"><FontAwesomeIcon icon={faCalendar}/></span>
                  </p>
                <input id="startTime" className="input is-small"  type="time" onChange={(e) => {
                  setInputValue({...inputValue, start: e.target.value})
                  setEndTime(e.target.value)
                }}/>
                <p><span className="tag is-primary">開始</span></p>
              </div>
              <div className="timepicker">
                <p className="control has-icons-left has-icons-right">
                  <input className="input is-small" type="date" defaultValue={inputValue.endDate??""} onChange={(e) => {
                    setInputValue({...inputValue, endDate: e.target.value})
                  }}/>
                  <span className="icon is-small is-left"><FontAwesomeIcon icon={faCalendar}/></span>
                </p>
                <input id="endTime" value={endTime} className="input is-small" max="28:00:00" type="time" onChange={(e) => {
                  setInputValue({...inputValue, end: e.target.value})
                  setEndTime(e.target.value)
                }}/>
                <p><span className="tag is-primary">終了</span></p>

              </div>
            </div>
            <button className="button is-info is-fullwidth" onClick={async () => {
              if (!inputValue.date || !inputValue.start || !inputValue.end || !inputValue.application_id) {
                console.log(inputValue)
                alert("未記入の項目があります");
                return
              }

              const response: any = await axios.post("https://api.miscolle.com/v1/schedule/", {
                "application_id": inputValue.application_id,
                "start": `${inputValue.date} ${inputValue.start}`,
                "end": `${inputValue.endDate} ${inputValue.end}`
              }, {
                headers: header
              }).catch((err) => {
                alert("登録に失敗しました。内容を確認ください。");
              });
              const tmp = [...schedules, response.data?.schedule];
              tmp.sort((a, b) => {
                if (dayjs(a.start).isAfter(b.start)) {
                  return 1
                } else {
                  return -1
                }
              });
              setSchedules(tmp);

              alert("スケジュールを登録しました!")
              setInputValue({application_id: inputValue.application_id});
              document.querySelectorAll('input').forEach((e) => {
                e.value = ''
              })

            }}><FontAwesomeIcon icon={faPlusCircle}/> 追加
            </button>
          </div>
          <hr/>
          <div className="list">
            <h3 className="is-size-6 has-text-weight-bold">登録済みのスケジュール</h3>
            {
              schedules.map(schedule => {
                return <ScheduleItem key={schedule.id} schedule={schedule} applications={applications} header={header}/>
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
